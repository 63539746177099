import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LanguageToggler from "../components/languageToggler"
import Arrow from "../assets/svg/arrow.svg"
import styles from "../components/styles/index.module.css"
import SvgAnimation from "../components/svgAnimation"

const ENIndexPage = ({ data }) => {
  return (
    <Layout bgColor="#fd9d6e">
      <SEO title="Home" description="Lucas Boh’s personal website" />
      <div className={styles.mainContainer}>
        <LanguageToggler />
        <SvgAnimation />

        <nav className={styles.nav}>
          <a
            className={styles.navItem}
            href="https://estudioobliquo.com/#proyectos"
            target="_blank"
          >
            Web design<br /> and development 
            <Arrow />
          </a>
          <a
            className={styles.navItem}
            href="https://linktr.ee/puntodefugapodcast"
            target="_blank"
          >
            Content creation 
            <Arrow />
          </a>
          <a className={styles.navItem} href="#info">
            info + contact
            <Arrow />
          </a>
        </nav>
        <div className={styles.imgContainer}>
          <Img fluid={data.indeximg.childImageSharp.fluid} />
        </div>
        <a
          className={styles.mail}
          href="mailto:contacto@lucasboh.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          contacto@lucasboh.com
        </a>
        <div className={styles.info} id="info">
          <h2>About</h2>
          <p>
            I&#39;m Lucas Boh from Asunción, Paraguay, and I currently live in
            Maastricht, the Netherlands, where I'm studying a Master in Public Policy and Human Development at UNU-MERIT / Maastricht University. I am a media engineer (B. Eng. Medientechnik)
            and cofounder of{" "}
            <a
              className="wave"
              href="https://estudioobliquo.com/"
              target="_blank"
            >
              Estudio Obliquo
            </a>
            , where I work as a web designer and developer.
          </p>
          <p>
            I&#39;m also the co-creator and producer of{" "}
            <a
              className="wave"
              href="https://linktr.ee/puntodefugapodcast"
              target="_blank"
            >
              Punto de Fuga
            </a>{" "}
            , an independent journalism platform that creates digital content, in the form of podcasts and videos, that seeks to objectively explain complex situations and problems that take place in Paraguay.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ENIndexPage

export const query = graphql`
  query EnIndexQuery {
    indeximg: file(relativePath: { eq: "index.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
